import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  submitWithToken(e) {
    window.onbeforeunload = null; // Remove any unload warnings
    e.preventDefault();
    const form = document.querySelector(e.target.dataset.formId);

    if (form) {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const tokenInput = document.createElement("input");
      tokenInput.setAttribute("type", "hidden");
      tokenInput.setAttribute("name", "authenticity_token");
      tokenInput.setAttribute("value", token);
      form.appendChild(tokenInput);
      form.submit();
    } else {
      console.error("No form found");
    }
  }
}
